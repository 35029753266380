@import '../../style/themes/index';
@import '../../style/mixins/index';

@btn-prefix-cls: ~'@{ant-prefix}-btn';

.@{btn-prefix-cls} {
  &-rtl {
    direction: rtl;
  }

  &-primary {
    .@{btn-prefix-cls}-group &:last-child:not(:first-child),
    .@{btn-prefix-cls}-group & + & {
      .@{btn-prefix-cls}-group-rtl& {
        border-right-color: @btn-group-border;
        border-left-color: @btn-default-border;
      }
      &[disabled] {
        .@{btn-prefix-cls}-group-rtl& {
          border-right-color: @btn-default-border;
          border-left-color: @btn-group-border;
        }
      }
    }
  }

  & > &-loading-icon {
    .@{iconfont-css-prefix} {
      .@{btn-prefix-cls}-rtl& {
        padding-right: 0;
        padding-left: @margin-xs;
      }
    }
  }

  > .@{iconfont-css-prefix} + span,
  > span + .@{iconfont-css-prefix} {
    .@{btn-prefix-cls}-rtl& {
      margin-right: 8px;
      margin-left: 0;
    }
  }
}

// mixin
.btn-group(@btnClassName: btn) {
  .@{btnClassName} + .@{btnClassName},
  .@{btnClassName} + &,
  span + .@{btnClassName},
  .@{btnClassName} + span,
  > span + span,
  & + .@{btnClassName},
  & + & {
    .@{btnClassName}-rtl&,
    .@{btnClassName}-group-rtl& {
      margin-right: -1px;
      margin-left: auto;
    }
  }

  &.@{btnClassName}-group-rtl {
    direction: rtl;
  }

  > .@{btnClassName}:first-child:not(:last-child),
  > span:first-child:not(:last-child) > .@{btnClassName} {
    .@{btnClassName}-group-rtl& {
      border-top-left-radius: 0;
      border-top-right-radius: @btn-border-radius-base;
      border-bottom-right-radius: @btn-border-radius-base;
      border-bottom-left-radius: 0;
    }
  }

  > .@{btnClassName}:last-child:not(:first-child),
  > span:last-child:not(:first-child) > .@{btnClassName} {
    .@{btnClassName}-group-rtl& {
      border-top-left-radius: @btn-border-radius-base;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: @btn-border-radius-base;
    }
  }

  &-sm {
    > .@{btnClassName}:first-child:not(:last-child),
    > span:first-child:not(:last-child) > .@{btnClassName} {
      .@{btnClassName}-group-rtl& {
        border-top-left-radius: 0;
        border-top-right-radius: @btn-border-radius-sm;
        border-bottom-right-radius: @btn-border-radius-sm;
        border-bottom-left-radius: 0;
      }
    }

    > .@{btnClassName}:last-child:not(:first-child),
    > span:last-child:not(:first-child) > .@{btnClassName} {
      .@{btnClassName}-group-rtl& {
        border-top-left-radius: @btn-border-radius-sm;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: @btn-border-radius-sm;
      }
    }
  }
}
