@import '../../style/themes/index';
@import '../../style/mixins/index';

@anchor-border-width: 2px;

.@{ant-prefix}-anchor {
  &-rtl {
    direction: rtl;
  }

  &-wrapper {
    .@{ant-prefix}-anchor-rtl& {
      margin-right: -4px;
      margin-left: 0;
      padding-right: 4px;
      padding-left: 0;
    }
  }

  &-ink {
    .@{ant-prefix}-anchor-rtl & {
      right: 0;
      left: auto;
    }

    &-ball {
      .@{ant-prefix}-anchor-rtl & {
        right: 50%;
        left: 0;
        transform: translateX(50%);
      }
    }
  }

  &-link {
    .@{ant-prefix}-anchor-rtl & {
      padding: 7px 16px 7px 0;
    }
  }
}
