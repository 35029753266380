@import '../../style/mixins/index';

.antCheckboxFn(@checkbox-prefix-cls: ~'@{ant-prefix}-checkbox') {
  .@{checkbox-prefix-cls}-rtl {
    direction: rtl;
  }

  .@{checkbox-prefix-cls}-group {
    &-item {
      .@{checkbox-prefix-cls}-group-rtl & {
        margin-right: 0;
        margin-left: 8px;
      }
      &:last-child {
        .@{checkbox-prefix-cls}-group-rtl & {
          margin-left: 0 !important;
        }
      }
    }
    &-item + &-item {
      .@{checkbox-prefix-cls}-group-rtl & {
        margin-left: 8px;
      }
    }
  }
}
