@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}

[ant-click-animating='true'],
[ant-click-animating-without-extra-node='true'] {
  position: relative;
}

html {
  --antd-wave-shadow-color: @primary-color;
  --scroll-bar: 0;
}

[ant-click-animating-without-extra-node='true']::after,
.ant-click-animating-node {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: inherit;
  box-shadow: 0 0 0 0 @primary-color;
  box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  opacity: 0.2;
  animation: fadeEffect 2s @ease-out-circ, waveEffect 0.4s @ease-out-circ;
  animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}

@keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 @primary-color;
    box-shadow: 0 0 0 @wave-animation-width var(--antd-wave-shadow-color);
  }
}

@keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
