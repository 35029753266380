@import '../../style/themes/index';
@import '../../style/mixins/index';

@calendar-prefix-cls: ~'@{ant-prefix}-picker-calendar';
@calendar-picker-prefix-cls: ~'@{ant-prefix}-picker';

.@{calendar-prefix-cls} {
  &-rtl {
    direction: rtl;
  }

  &-header {
    .@{calendar-prefix-cls}-month-select {
      .@{calendar-prefix-cls}-rtl & {
        margin-right: @padding-xs;
        margin-left: 0;
      }
    }

    .@{calendar-prefix-cls}-mode-switch {
      .@{calendar-prefix-cls}-rtl & {
        margin-right: @padding-xs;
        margin-left: 0;
      }
    }
  }

  // ========================== Full ==========================
  &-full {
    .@{calendar-picker-prefix-cls}-panel {
      .@{calendar-prefix-cls}-rtl& {
        text-align: left;
      }

      .@{calendar-picker-prefix-cls}-body {
        th {
          .@{calendar-prefix-cls}-rtl& {
            padding: 0 0 5px 12px;
          }
        }
      }
    }
  }
}
