@import './index';
@import './mixin';

@input-affix-margin: 4px;

.@{ant-prefix}-input {
  &-affix-wrapper {
    .input();
    display: inline-flex;
    max-height: @input-height-base;

    &-lg {
      max-height: @input-height-lg;
    }

    &-sm {
      max-height: @input-height-sm;
    }

    &-disabled {
      .@{ant-prefix}-input[disabled] {
        background: transparent;
      }
    }

    > input.@{ant-prefix}-input {
      padding-right: 0;
      padding-left: 0;
      border: none;
      outline: none;

      &:focus {
        box-shadow: none;
      }
    }

    &::before {
      width: 0;
      visibility: hidden;
      content: '\a0';
    }
  }

  &-prefix,
  &-suffix {
    display: flex;
    flex: none;
    align-items: center;
  }

  &-prefix {
    margin-right: @input-affix-margin;
  }

  &-suffix {
    margin-left: @input-affix-margin;
  }
}
