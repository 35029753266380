@import '../../style/themes/index';
@import '../../style/mixins/index';

@alert-prefix-cls: ~'@{ant-prefix}-alert';

.@{alert-prefix-cls} {
  &&-rtl {
    padding: 8px 37px 8px 15px;
    direction: rtl;
  }

  &&-no-icon {
    .@{alert-prefix-cls}-rtl& {
      padding: @alert-no-icon-padding-vertical 15px;
    }
  }

  &&-closable {
    .@{alert-prefix-cls}.@{alert-prefix-cls}-rtl& {
      padding-right: 37px;
      padding-left: 30px;
    }
  }

  &&-no-icon&-closable {
    .@{alert-prefix-cls}.@{alert-prefix-cls}-rtl& {
      padding-right: 15px;
      padding-left: 30px;
    }
  }

  &-icon {
    .@{alert-prefix-cls}-rtl & {
      right: 16px;
      left: auto;
    }
  }

  &-close-icon {
    .@{alert-prefix-cls}-rtl & {
      right: auto;
      left: 16px;
    }
  }

  &-with-description,
  &-with-description&-closable {
    .@{alert-prefix-cls}.@{alert-prefix-cls}-rtl& {
      padding: 15px 64px 15px 15px;
    }
  }

  &-with-description&-no-icon {
    .@{alert-prefix-cls}.@{alert-prefix-cls}-rtl& {
      padding: 15px;
    }
  }

  &-with-description &-icon {
    .@{alert-prefix-cls}-rtl& {
      right: 24px;
      left: auto;
    }
  }

  &-with-description &-close-icon {
    .@{alert-prefix-cls}-rtl& {
      right: auto;
      left: 16px;
    }
  }
}
