@import '../../style/themes/index';
@import '../../style/mixins/index';
@import './card-style';

@tab-prefix-cls: ~'@{ant-prefix}-tabs';

.@{tab-prefix-cls} {
  &-rtl {
    direction: rtl;
  }

  &-ink-bar {
    .@{tab-prefix-cls}-rtl & {
      right: 0;
      left: auto;
    }
  }

  &-tab-next {
    .@{tab-prefix-cls}-rtl & {
      right: auto;
      left: 2px;
    }
  }

  &-tab-prev {
    .@{tab-prefix-cls}-rtl & {
      right: 2px;
      left: auto;
    }
  }

  &-nav {
    .@{tab-prefix-cls}-tab {
      .@{tab-prefix-cls}-rtl & {
        margin: @tabs-horizontal-margin-rtl;
      }

      .@{iconfont-css-prefix} {
        .@{tab-prefix-cls}-rtl & {
          margin-right: 0;
          margin-left: @margin-xs;
        }
      }
    }
  }

  .@{tab-prefix-cls}-left-bar,
  .@{tab-prefix-cls}-right-bar {
    .@{tab-prefix-cls}-tab {
      .@{tab-prefix-cls}-rtl& {
        margin: @tabs-vertical-margin;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .@{tab-prefix-cls}-right-bar {
    .@{tab-prefix-cls}-ink-bar {
      .@{tab-prefix-cls}-rtl& {
        right: auto;
        left: 1px;
      }
    }
  }

  // card
  &&-card &-card-bar &-tab &-close-x {
    .@{tab-prefix-cls}-rtl& {
      margin-right: 3px;
      margin-left: -5px;
    }
  }

  &-extra-content {
    .@{tab-prefix-cls}-rtl & {
      float: left !important;
    }
  }
}
